// Libraries
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

// Components
import AntdSpace, { SpaceProps } from 'antd/lib/space';

// Css

export const Space: React.FC<SpaceProps> = styled(AntdSpace)`
  &.ant-space-vertical {
    ${tw`ants-w-full`}
  }
`;
