// Libraries
import { get } from 'lodash';

// Utils
import { handleError } from './handleError';

// Constants
const PATH = 'src/app/utils/cookie.ts';

export const setCookie = (name: string, value: any, exdays: number, domain: string) => {
  try {
    const { protocol = 'https:' } = get(window, 'location', '') || {};

    let d = new Date();

    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

    if (protocol === 'https:') {
      document.cookie = `${name}=${value}; Domain=${domain}; Path=/; Expires=${d.toUTCString()}; SameSite=None; Secure`;
    } else {
      document.cookie = `${name}=${value}; Domain=${domain}; Path=/; Expires=${d.toUTCString()};`;
    }
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'setCookie',
      args: {
        name,
        value,
        exdays,
        domain,
      },
    });
  }
};

export const getCookie = (cname: string): string => {
  try {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length) || '';
      }
    }

    return '';
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'getCookie',
      args: {
        cname,
      },
    });

    return '';
  }
};
