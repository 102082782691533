// Libraries
import { useSelector } from 'react-redux';

// Utils
import { getCookie } from 'app/utils/cookie';

// Types
import { RootState } from 'types';
import { LayoutState } from 'app/modules/slice/types';

// Constants
import { APP_CONFIG } from 'constants/appConfig';

export const useUserInfo = () => {
  const layoutSlice = (useSelector<RootState>(state => state?.layout) || {}) as LayoutState;
  const { user = { token: '', user_id: '', account_id: '' } } = layoutSlice;

  let { token = null, user_id = null, account_id = null } = user;

  if (!token || !user_id || !account_id) {
    let userInfo: any = getCookie(APP_CONFIG.U_OGS);

    if (userInfo) {
      userInfo = JSON.parse(decodeURIComponent(userInfo));

      if (userInfo.user_id && userInfo.account_id) {
        let userId = userInfo.user_id;
        let accountId = userInfo.account_id;

        let switchAccountInfo: any = sessionStorage.getItem('ADX_BUYER.persistent.switch.' + accountId);

        if (switchAccountInfo) {
          switchAccountInfo = JSON.parse(switchAccountInfo);

          if (switchAccountInfo.user_id) {
            accountId = switchAccountInfo.user_id;
            userId = switchAccountInfo.user_id;
          }
        }

        let supportAccountInfo: any = sessionStorage.getItem('ADX_BUYER.persistent.su.' + accountId);

        if (supportAccountInfo) {
          supportAccountInfo = JSON.parse(supportAccountInfo);
          userId = typeof supportAccountInfo.user_id !== 'undefined' ? supportAccountInfo.user_id : userId;
        }

        if (userInfo.token && userId) {
          token = userInfo.token;
          user_id = userId;
          account_id = accountId;
        }
      }
    }
  }

  return { token, user_id, account_id };
};
