declare var SITE_URL: string;
declare var STATIC_DOMAIN: string;
declare var LOGO_MAIN: string;
declare var LOGO_SUB: string;
declare var ASSETS_URL: string;
declare var API_VERSION: string;
declare var API_HOST: string;
declare var API_LOGGING: string;
declare var NETWORK_ID: number;
declare var ST_VERSION: number;
declare var U_OGS: string;
declare var PERMISSION_DOMAIN: string;
declare var AUTH_ADX_DOMAIN: string;
declare var COLUMN_DOMAIN: string;
declare var UPLOAD_URL: string;
declare var APPLICATION_ENV: string;
declare var PERMISSION_APP_CODE: string;
declare var LOGIN_URL: string;
declare var LOGOUT_URL: string;
declare var PROFILE_URL: string;
declare var FROALA_API_KEY: string;
declare var URL_SOCKET: string;
declare var CDP_DOMAIN: string;
declare var CDP_APP_DOMAIN: string;

const CDP_DOMAIN_CONFIG = CDP_DOMAIN || process.env.CDP_DOMAIN || '';
const SITE_URL_CONFIG = SITE_URL || process.env.SITE_URL || '';
const STATIC_DOMAIN_CONFIG = STATIC_DOMAIN || process.env.STATIC_DOMAIN || '';
const LOGO_MAIN_CONFIG = LOGO_MAIN || process.env.LOGO_MAIN || '';
const LOGO_SUB_CONFIG = LOGO_SUB || process.env.LOGO_SUB || '';
const ASSETS_URL_CONFIG = ASSETS_URL || process.env.ASSETS_URL || '';
const API_VERSION_CONFIG = API_VERSION || process.env.API_VERSION || '';
const API_HOST_CONFIG = API_HOST || process.env.API_HOST || '';
const API_URL_CONFIG = `${API_HOST_CONFIG}/api/${API_VERSION_CONFIG}` || '';
const API_LOGGING_CONFIG = API_LOGGING || process.env.API_LOGGING || '';
const NETWORK_ID_CONFIG = NETWORK_ID || process.env.NETWORK_ID || '';
const ST_VERSION_CONFIG = ST_VERSION || process.env.ST_VERSION || '';
const U_OGS_CONFIG = U_OGS || process.env.U_OGS || '';
const PERMISSION_DOMAIN_CONFIG = PERMISSION_DOMAIN || process.env.PERMISSION_DOMAIN || '';
const AUTH_ADX_DOMAIN_CONFIG = AUTH_ADX_DOMAIN || process.env.AUTH_ADX_DOMAIN || '';
const COLUMN_DOMAIN_CONFIG = COLUMN_DOMAIN || process.env.COLUMN_DOMAIN || '';
const UPLOAD_URL_CONFIG = UPLOAD_URL || process.env.UPLOAD_URL || '';
const APPLICATION_ENV_CONFIG = APPLICATION_ENV || process.env.APPLICATION_ENV || '';
const PERMISSION_APP_CODE_CONFIG = PERMISSION_APP_CODE || process.env.PERMISSION_APP_CODE || '';
const LOGIN_URL_CONFIG = LOGIN_URL || process.env.LOGIN_URL || '';
const LOGOUT_URL_CONFIG = LOGOUT_URL || process.env.LOGOUT_URL || '';
const PROFILE_URL_CONFIG = PROFILE_URL || process.env.PROFILE_URL || '';
const FROALA_API_KEY_CONFIG = FROALA_API_KEY || process.env.FROALA_API_KEY || '';
const URL_SOCKET_CONFIG = URL_SOCKET || process.env.URL_SOCKET || '';
const CDP_APP_DOMAIN_CONFIG = CDP_APP_DOMAIN || process.env.CDP_APP_DOMAIN || '';

export const APP_CONFIG = {
  CDP_DOMAIN: CDP_DOMAIN_CONFIG,
  URL_SOCKET: URL_SOCKET_CONFIG,
  SITE_URL: SITE_URL_CONFIG,
  STATIC_DOMAIN: STATIC_DOMAIN_CONFIG,
  CDP_APP_DOMAIN: CDP_APP_DOMAIN_CONFIG,
  LOGO_MAIN: LOGO_MAIN_CONFIG,
  LOGO_SUB: LOGO_SUB_CONFIG,
  ASSETS_URL: ASSETS_URL_CONFIG,
  API_HOST: API_HOST_CONFIG,
  API_URL: API_URL_CONFIG,
  API_VERSION: API_VERSION_CONFIG,
  API_LOGGING: API_LOGGING_CONFIG,
  NETWORK_ID: NETWORK_ID_CONFIG,
  ST_VERSION: ST_VERSION_CONFIG,
  U_OGS: U_OGS_CONFIG,
  PERMISSION_DOMAIN: PERMISSION_DOMAIN_CONFIG,
  AUTH_ADX_DOMAIN: AUTH_ADX_DOMAIN_CONFIG,
  COLUMN_DOMAIN: COLUMN_DOMAIN_CONFIG,
  UPLOAD_URL: UPLOAD_URL_CONFIG,
  APPLICATION_ENV: APPLICATION_ENV_CONFIG,
  PERMISSION_APP_CODE: PERMISSION_APP_CODE_CONFIG,
  LOGIN_URL: LOGIN_URL_CONFIG,
  LOGOUT_URL: LOGOUT_URL_CONFIG,
  PROFILE_URL: PROFILE_URL_CONFIG,
  FROALA_API_KEY: FROALA_API_KEY_CONFIG,
  ACCOUNT_TYPE_CLIENT: 1,
  ACCOUNT_TYPE_MANAGER: 2,
  PASSWORD_LENGTH_LESS_NUMBER: 8,
  WORKSPACE_EXPORT_ROOT: 'workspace-export-root',
  GLOBAL_STYLE_MARKER: '/*GLOBAL_STYLE_MARKER*/',
  MESSAGE: {
    MEDIA_TEMPLATE_THUBNAIL: 'media-template-thumbnail',
  },
  DYNAMIC_ATTRIBUTE: {
    DATA_IS_DYNAMIC: 'data-is-dynamic',
    DATA_DYNAMIC_INDEX: 'data-dynamic-index',
    DISPLAY: 'data-dynamic-display',
    PREVIEW: 'data-dynamic-preview',
  },
};
