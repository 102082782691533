import { useEffect, useState } from 'react';
import moment from 'moment';

type TDisplay = {
  days: boolean;
  hours: boolean;
  minutes: boolean;
  seconds: boolean;
};

const useCountdown = (targetDate: number, display?: TDisplay) => {
  const countDownDate = moment(targetDate).valueOf();
  const [countDown, setCountDown] = useState(countDownDate - moment().valueOf());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - moment().valueOf());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate, targetDate]);

  return getReturnValues(countDown, display);
};

const getReturnValues = (epochCountDown: number, display?: TDisplay) => {
  let days = '00';
  let hours = '00';
  let minutes = '00';
  let seconds = '00';
  if (epochCountDown > 0) {
    // Time calculations for days, hours, minutes and seconds
    days = minTwoDigits(Math.floor(epochCountDown / (1000 * 60 * 60 * 24)));
    hours = minTwoDigits(Math.floor((epochCountDown / (1000 * 60 * 60)) % 24));
    minutes = minTwoDigits(Math.floor((epochCountDown / 1000 / 60) % 60));
    seconds = minTwoDigits(Math.floor((epochCountDown / 1000) % 60));

    if (display) {
      // if display is set, return only the values that are set to true

      if (!display.days) {
        // if days is not displayed

        days = '00';
        hours = minTwoDigits(Math.floor(epochCountDown / (1000 * 60 * 60)));

        if (!display.hours) {
          // if hours is not displayed
          hours = '00';
          minutes = minTwoDigits(Math.floor(epochCountDown / 1000 / 60));

          if (!display.minutes) {
            // if minutes is not displayed
            minutes = '00';
            seconds = minTwoDigits(Math.floor(epochCountDown / 1000));
          }
        } else {
          // if hours is displayed

          if (!display.minutes) {
            // if minutes is not displayed
            minutes = '00';
            seconds = minTwoDigits(Math.floor((epochCountDown / 1000) % (60 * 60)));
          }
        }
      } else {
        // if days is displayed

        if (!display.hours) {
          // if hours is not displayed
          hours = '00';
          minutes = minTwoDigits(Math.floor((epochCountDown / 1000 / 60) % (60 * 24)));

          if (!display.minutes) {
            // if minutes is not displayed
            minutes = '00';
            seconds = minTwoDigits(Math.floor(epochCountDown / 1000) % (60 * 60 * 24));
          }
        } else {
          // if hours is displayed

          if (!display.minutes) {
            // if minutes is not displayed
            minutes = '00';
            seconds = minTwoDigits(Math.floor((epochCountDown / 1000) % (60 * 60)));
          }
        }
      }
    }
  }

  return [days, hours, minutes, seconds];
};

function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}

export { useCountdown };
