// Libraries
import get from 'lodash/get';

// Services
import { services } from 'app/services';

// Constants
import { APP_CONFIG } from 'constants/appConfig';

type TParams = {
  functionName: string;
  fileName: string;
  input: Record<string, any>;
  error: Record<string, any>;
};

export const sendNotify = async (params: TParams): Promise<any[] | null> => {
  try {
    const response = await services.create({
      API_HOST: `${APP_CONFIG.API_URL}/notify`,
      ...params,
    });

    return get(response, 'data.data', null);
  } catch (error) {
    return new Promise((_, reject) => reject(error));
  }
};
