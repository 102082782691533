// Libraries
import * as React from 'react';
import styled from 'styled-components';

// Antd Components
import AntdAvatar, { AvatarProps as AntdAvatarProps, GroupProps as AntGroupProps } from 'antd/lib/avatar';

// Css

interface AvatarProps extends AntdAvatarProps {}

export const Avatar: React.FC<AvatarProps> = styled(AntdAvatar)``;

export const AvatarGroup: React.FC<AntGroupProps> = styled(AntdAvatar.Group)``;
