// Libraries
import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface DividerProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  type?: 'vertical' | 'horizontal';
  dot?: boolean;
  dashed?: boolean;
  height?: number | string;
  width?: number | string;
}

export const Divider: React.FC<DividerProps> = props => {
  const { type, dot, dashed, className, style, width, height, children, ...restOf } = props;

  return (
    <Wrapper
      className={classNames(`--${type}`, className, {
        '--dot': dot,
        '--dashed': dashed,
      })}
      style={{ ...style, height, width }}
      {...restOf}
    />
  );
};

Divider.defaultProps = {
  type: 'horizontal',
  dot: false,
};

const Wrapper = styled.div`
  &.--horizontal {
    ${tw`ants-border-t ants-border-divider ants-w-full ants-my-5`}
  }

  &.--vertical {
    ${tw`ants-border-r ants-border-divider ants-min-h-[30px] ants-w-max ants-mx-2.5`}
  }

  &.--dot {
    ${tw`ants-border-dotted ants-border-gray-2`}

    &.--vertical {
      ${tw`ants-border-r-2`}
    }
  }

  &.--dashed {
    ${tw`ants-border-dashed ants-border-gray-2`}

    &.--vertical {
      ${tw`ants-border-r-2 `}
    }
  }
`;
