// Antd Icons
import { LoadingOutlined } from '@ant-design/icons';
import { SpinProps as AntdSpinProps } from 'antd/lib/spin';

// Styled
import { StyledSpin } from './styled';

interface TSpinProps extends AntdSpinProps {
  indicatorSize?: number;
  children?: any;
}

export const Spin = (props: TSpinProps) => {
  const { indicatorSize, ...restOf } = props;
  return (
    <StyledSpin
      {...{
        indicator: <LoadingOutlined className="ants-text-primary" style={{ fontSize: indicatorSize || 30 }} spin />,
        ...restOf,
      }}
    >
      {props.children}
    </StyledSpin>
  );
};

Spin.defaultProps = {};
