// Libraries
import React, { useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface TitleProps extends React.HtmlHTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const CustomTitle: React.FC<TitleProps> = props => {
  const { level, children, ...restOf } = props;

  const headEl = useMemo(() => {
    return `h${level}`;
  }, [level]);

  return React.createElement(headEl, restOf, children);
};

export const Title = styled(CustomTitle)`
  ${tw`ants-font-bold`}
`;

Title.defaultProps = {
  level: 6,
};
