// Libraries
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import pako from 'pako';

// Types
import { EnhancedStore } from '@reduxjs/toolkit';
import { RootState } from 'types';

// Utils
import { handleError } from 'app/utils/handleError';
import { searchPramsToObject } from 'app/utils/web';
import { getCookie, setCookie } from 'app/utils/cookie';

// Constants
import { APP_CONFIG } from 'constants/appConfig';

const CancelToken = axios.CancelToken;

const PATH = 'Src/services/services.js';

const isDebugApi = searchPramsToObject((window.location?.href || '').split('?')[1])['debug-api'] != null;

type UpdateParams = {
  id?: string | number;
  API_HOST: string;
  config?: AxiosRequestConfig;
  isGzip?: boolean;
};

let store: EnhancedStore;
export const injectStore = _store => {
  store = _store;
};

export const instance = axios.create();

instance.interceptors.request.use(
  function (config) {
    try {
      // if (config.cancelToken) {
      //   return config;
      // }

      // Do something before request is sent
      const rootState: RootState = store.getState();
      const { token, user_id, account_id } = rootState.layout?.user || {};

      if (token && user_id && config.url && config.method) {
        switch (config.method) {
          case 'post':
          case 'put':
          case 'delete':
            if (typeof config.url === 'string') {
              const authenticated = {
                _token: token,
                _user_id: user_id,
                _account_id: account_id,
              };

              if (config.url.indexOf('?') !== -1) {
                config.url = config.url + '&' + qs.stringify(authenticated);
              } else {
                config.url = config.url + '?' + qs.stringify(authenticated);
              }
            }
            break;
          default:
            if (config.params) {
              config.params = {
                ...config.params,
                _token: token,
                _user_id: user_id,
                _account_id: account_id,
              };
            }
            break;
        }
      }

      return config;
    } catch (e) {
      // Error
    }
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    try {
      return response;
    } catch (e) {
      // Error
    }
  },
  function (error) {
    try {
      // if (error.message === 'canceled') return;
      // Do something with response error
      if (APP_CONFIG.APPLICATION_ENV) {
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 302:
              if (getCookie(APP_CONFIG.U_OGS)) {
                // Remove cookie
                let arrTmp = window.location.hostname.split('.');

                if (arrTmp.length < 4) {
                  setCookie(APP_CONFIG.U_OGS, '', -1, arrTmp.slice(-2).join('.'));
                  window.location.reload();
                } else {
                  setCookie(APP_CONFIG.U_OGS, '', -1, arrTmp.slice(-3).join('.'));
                  window.location.reload();
                }
              }
              break;
            case 403:
            case 400:
            case 404:
            case 500:
            case 502:
            case 504:
              const { config = {}, response = {} } = error;
              const { url = '', method = '', params = {}, data = {} } = config;
              handleError(
                error,
                {
                  path: PATH,
                  action: 'API Error',
                  errorCode: error.response.status || response.data?.data,
                  errorMessage: response.data?.message,
                  requestUrl: url,
                  method: method.toString().toUpperCase(),
                  queryParams: params,
                  data,
                },
                false,
              );

              break;
          }
        }
      }

      return Promise.reject(error);
    } catch (error) {
      // Error
      handleError(error, {
        path: PATH,
        action: 'axios.interceptors.response',
        args: {},
      });
    }
  },
);

export const services = {
  get: function get(params) {
    if (typeof params.API_HOST !== 'undefined' && typeof params.id !== 'undefined') {
      const API_HOST = params.API_HOST;
      const cancelToken = params.cancelToken ? params.cancelToken : new CancelToken(function () {});

      delete params.API_HOST;
      delete params.cancelToken;

      return instance.get(API_HOST + '/' + params.id, {
        params: params,
        cancelToken: cancelToken,
      });
    } else {
      return false;
    }
  },
  getList: function getList(params): any {
    if (typeof params.API_HOST !== 'undefined') {
      const API_HOST = params.API_HOST;
      const cancelToken = params.cancelToken ? params.cancelToken : new CancelToken(function () {});
      const { signal } = params;

      delete params.API_HOST;
      delete params.cancelToken;
      delete params.signal;

      return instance.get(API_HOST, {
        params: params,
        cancelToken: cancelToken,
        signal,
      });
    } else {
      return false;
    }
  },
  create: function create(params): Promise<AxiosResponse<any, any> | any> {
    if (params.API_HOST !== 'undefined') {
      const API_HOST = params.API_HOST;
      const cancelToken = params.cancelToken ? params.cancelToken : new CancelToken(function () {});

      delete params.API_HOST;
      params.cancelToken && delete params.cancelToken;

      return instance.post(API_HOST, params, { cancelToken: cancelToken });
    } else {
      return Promise.resolve(false);
    }
  },
  update: function update(params: UpdateParams): Promise<AxiosResponse<any, any> | any> {
    const { id, API_HOST, config = {}, isGzip, ...restOfParams } = params;
    const data = isGzip && !isDebugApi ? pako.gzip(JSON.stringify(restOfParams)) : restOfParams;
    if (typeof API_HOST !== 'undefined' && typeof id !== 'undefined') {
      return instance.put(API_HOST + '/' + id, data, {
        ...config,
        ...(isGzip &&
          !isDebugApi && {
            headers: {
              ...config.headers,
              'Content-Type': 'application/json',
              'content-encoding': 'gzip',
            },
          }),
      });
    } else {
      return Promise.resolve(false);
    }
  },
  del: function del(params) {
    if (typeof params.API_HOST !== 'undefined' && typeof params.id !== 'undefined') {
      const API_HOST = params.API_HOST;

      delete params.API_HOST;
      let config = {
        params,
      };

      if (typeof params.config !== 'undefined' && Object.keys(params.config).length) {
        const copyConfig = params.config;

        delete params.config;

        config = {
          params,
          ...copyConfig,
        };
      }

      return instance.delete(API_HOST + '/' + params.id, config);
    } else {
      return false;
    }
  },
  download: function download(params) {
    if (typeof params.API_HOST !== 'undefined') {
      const API_HOST = params.API_HOST;

      delete params.API_HOST;

      return instance.get(API_HOST, {
        params: params,
        responseType: 'blob',
      });
    } else {
      return false;
    }
  },
  postDownload: function create(params) {
    if (params.API_HOST !== 'undefined') {
      const API_HOST = params.API_HOST;
      const cancelToken = params.cancelToken ? params.cancelToken : new CancelToken(function () {});

      delete params.API_HOST;
      params.cancelToken && delete params.cancelToken;

      return instance.post(API_HOST, params, {
        cancelToken: cancelToken,
        responseType: 'blob',
      });
    } else {
      return false;
    }
  },
  upload: function upload(params) {
    if (typeof params.API_HOST !== 'undefined') {
      const API_HOST = params.API_HOST;

      delete params.API_HOST;

      let url = API_HOST + '?' + qs.stringify(params.params);

      return instance.post(url, params.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      return false;
    }
  },
  uploadProgress: function upload(params) {
    if (typeof params.API_HOST !== 'undefined') {
      const API_HOST = params.API_HOST;

      delete params.API_HOST;

      let url = API_HOST + '?' + qs.stringify(params.params);

      return instance.post(url, params.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: data => {
          if (params.progressCallback && typeof params.progressCallback === 'function') {
            params.progressCallback(data);
          }
        },
      });
    } else {
      return false;
    }
  },
};

/** Create CDP Axios Instance */
export const CDPInstance = axios.create({
  baseURL: APP_CONFIG.CDP_DOMAIN,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

CDPInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const rootState: RootState = store.getState();
  const { token, user_id, account_id } = rootState.layout?.user || {};

  if (token && user_id && config.url && config.method) {
    config.params = {
      ...config.params,
      portalId: APP_CONFIG.NETWORK_ID,
      _user_id: user_id,
      _owner_id: account_id,
    };

    config.headers = {
      ...config.headers,
      token,
    };
  }

  return config;
});

/** Create Permission Axios Instance */
export const PermissionInstance = axios.create({
  baseURL: APP_CONFIG.PERMISSION_DOMAIN,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

PermissionInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const rootState: RootState = store.getState();
  const { token, user_id, account_id } = rootState.layout?.user || {};

  if (token && user_id && config.url && config.method) {
    config.params = {
      ...config.params,
      network_id: APP_CONFIG.NETWORK_ID,
      _user_id: user_id,
      _account_id: account_id,
      _token: token,
    };
  }

  return config;
});
