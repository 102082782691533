// Libraries
import styled from 'styled-components';

// Components
import AntdRadio from 'antd/lib/radio';

// Css

import tw from 'twin.macro';

export const Radio = styled(AntdRadio)`
  ${tw`ants-font-roboto ants-text-cus-base ants-text-normal`}
  .ant-radio {
    .ant-radio-inner {
      ${tw`ants-border-2 ants-border-primary`}

      &::after {
        ${tw`ants-bg-primary`}
      }
    }
  }
`;

export const RadioButton = styled(AntdRadio.Button)``;
export const RadioGroup = styled(AntdRadio.Group)``;
