export const QUERY_KEYS = {
  // SAVED BLOCKS
  ADD_SAVED_BLOCK: 'ADD_SAVED_BLOCK',
  UPDATE_SAVED_BLOCK: 'UPDATE_SAVED_BLOCK',
  DELETE_SAVED_BLOCK: 'DELETE_SAVED_BLOCK',
  GET_SAVED_BLOCKS: 'GET_SAVED_BLOCKS',
  GET_SAVED_DETAILS_BLOCK: 'GET_SAVED_DETAILS_BLOCK',

  GET_MEDIA_JSON_SAVED_DETAILS_BLOCK: 'GET_MEDIA_JSON_SAVED_DETAILS_BLOCK',
  GET_MEDIA_JSON_SAVED_BLOCK: 'GET_MEDIA_JSON_SAVED_BLOCK',
  GET_MEDIA_JSON_SAVED_BLOCK_DETAIL: 'GET_MEDIA_JSON_SAVED_BLOCK_DETAIL',

  // COLORS PROFILE
  ADD_COLOR_PROFILE: 'ADD_COLOR_PROFILE',
  DELETE_COLOR_PROFILE: 'DELETE_COLOR_PROFILE',
  GET_COLOR_PROFILES: 'GET_COLOR_PROFILES',

  // SAVED_IMAGE
  GET_SAVED_IMAGES: 'GET_SAVED_IMAGES',

  // BUSINESS OBJECT
  GET_LIST_BO: 'GET_LIST_BO',
  GET_BO_DETAIL: 'GET_BO_DETAIL',
  GET_DATA_TABLE_BO: 'GET_DATA_TABLE_BO',
  GET_LIST_SOURCE_BO: 'GET_LIST_SOURCE_BO',
  GET_EVENT_ATTRIBUTE_BO: 'GET_EVENT_ATTRIBUTE_BO',

  // TEMPLATE GALLERY
  GET_LIST_GALLERY_TEMPLATE: 'GET_LIST_GALLERY_TEMPLATE',
  GET_LIST_MEDIA_JSON_GALLERY_TEMPLATE: 'GET_LIST_MEDIA_JSON_GALLERY_TEMPLATE',
  GET_LIST_MEDIA_TEMPLATE_TYPE: 'GET_LIST_MEDIA_TEMPLATE_TYPE',

  // Dynamic content Attr
  GET_LIST_DYNAMIC_CONTENT_ATTR: 'GET_LIST_DYNAMIC_CONTENT_ATTR',

  // Event tracking attributes
  GET_EVENT_TRACKING_ATTRIBUTES: 'GET_EVENT_TRACKING_ATTRIBUTES',

  // Promotion Code Attr
  GET_LIST_PROMOTION_CODE_ATTR: 'GET_LIST_PROMOTION_CODE_ATTR',

  // Third party
  GET_LIST_FALLBACK_BO: 'GET_LIST_FALLBACK_BO',
  GET_USER_INFO: 'GET_USER_INFO',

  // Event attr
  GET_LIST_EVENT_ATTR: 'GET_LIST_EVENT_ATTR',
  GET_LIST_EVENT_BY_SOURCE: 'GET_LIST_EVENT_BY_SOURCE',
  GET_LIST_SOURCE_BY_EVENT: 'GET_SOURCE_BY_EVENT',
  GET_LIST_ALL_EVENT: 'GET_LIST_ALL_EVENT',

  // COLLECTIONS
  GET_LIST_COLLECTION: 'GET_LIST_COLLECTION',

  // Event Pool
  GET_LIST_PROMOTION_POOL: 'GET_LIST_PROMOTION_POOL',
  // Custom Function
  GET_LIST_CUSTOM: 'GET_LIST_CUSTOM',
  GET_SAVE_CUSTOM_FUNCTION: 'GET_SAVE_CUSTOM_FUNCTION',

  // Template Listing
  GET_TEMPLATE_CATEGORY_LIST: 'GET_TEMPLATE_CATEGORY_LIST',
  GET_OBJECT_TEMPLATE_LIST: 'GET_OBJECT_TEMPLATE_LIST',
};

export const QUERY_LIMIT_RESULTS = 10;
