// Libraries
import get from 'lodash/get';

// Services
import * as thirdInfoServices from 'app/services/3rd/info';

// Constants
import { APP_CONFIG } from 'constants/appConfig';

// Models
import { TUserInfo } from 'app/models/UserInfo';

export const getUserInfo = async (user_id: string | number): Promise<TUserInfo> => {
  try {
    const response = await thirdInfoServices.getList({
      type: 'user-info',
      userId: user_id,
      networkId: APP_CONFIG.NETWORK_ID,
    });
    return get(response, 'data.data', null);
  } catch (error) {
    return new Promise((_, reject) => reject(error));
  }
};
