import { services } from '..';
import { APP_CONFIG } from 'constants/appConfig';

export function get(params) {
  return services.get({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/3rd/info',
  });
}

export function getList(params) {
  return services.getList({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/3rd/info',
  });
}

export function create(params) {
  return services.create({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/3rd/info',
  });
}

export function update(params) {
  return services.update({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/3rd/info',
  });
}

export function del(params) {
  return services.del({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/network/info',
  });
}
