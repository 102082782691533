// Libraries
import React from 'react';
import { TagProps as AntdTagProps, Tag as AntdTag } from 'antd';

// Atoms
import { Text } from 'app/components/atoms';

// Styled
import { TagWrapper } from './styled';

interface TagProps extends AntdTagProps {
  isUnsetMaxWidth?: boolean;
}

export const Tag: React.FC<TagProps> = props => {
  const { children, isUnsetMaxWidth, ...restOf } = props;

  return (
    <TagWrapper>
      <AntdTag {...restOf}>
        <Text className="tag-label" style={{ maxWidth: (isUnsetMaxWidth as any) && 'unset' }}>
          {children}
        </Text>
      </AntdTag>
    </TagWrapper>
  );
};

export const DefaultTag = AntdTag;

Tag.defaultProps = {
  color: 'blue',
};
