import React, { memo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface LogoProps extends React.HTMLAttributes<HTMLImageElement> {
  url: any;
  width?: string | number;
  height?: string | number;
  children?: React.ReactNode;
}

export const Logo: React.FC<LogoProps> = memo(props => {
  const { url, width, height, children, style, ...restOf } = props;

  return (
    <Wrapper src={url} style={{ width, height, ...style }} width={width} height={height} {...restOf}>
      {children}
    </Wrapper>
  );
});

const Wrapper = styled.img`
  ${tw`ants-object-center ants-object-cover`}
`;
