import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';
import tw from 'twin.macro';

export const Link = styled(RouterLink)`
  ${tw`ants-text-primary ants-underline ants-transition-all`}

  &:hover {
    ${tw`ants-text-primary ants-opacity-[0.8]`}
  }
`;
