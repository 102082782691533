// Libraries
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

// Components
import AntsIcon from '@antscorp/icons';

interface IconProps {
  size?: number;
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
}

export const Icon = styled(AntsIcon)<IconProps>`
  ${p => {
    return p.style ? css`style` : null;
  }}
  ${p => {
    return p.size
      ? css`
          width: ${p.width || p.size}px !important;
          height: ${p.height || p.size}px !important;
          font-size: ${p.size}px !important;
        `
      : tw`ants-text-xl ants-leading-none`;
  }}

  ${p => {
    return p.color
      ? css`
          color: ${p.color} !important;
        `
      : null;
  }}

  ${p =>
    p.disabled
      ? css`
          cursor: not-allowed !important;
          color: var(--text-disable-color) !important;
        `
      : null}
`;
