export const ROUTES = {
  HOME: {
    key: 'home',
    name: 'Home',
    path: '/home',
    exact: true,
    isPrivate: false,
  },
  LOGIN: {
    key: 'login',
    name: 'Login',
    path: '/login',
    exact: true,
    isPrivate: false,
  },
  DASHBOARD: {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    exact: false,
    isPrivate: true,
  },
  PUBLIC: {
    key: 'public',
    name: 'Public',
    path: '/public',
    exact: false,
    isPrivate: false,
  },

  /*  Public I-frame template */
  GALLERY_TEMPLATE: {
    key: 'galleryTemplate',
    name: 'Gallery template',
    path: '/public/gallery-template',
    isPrivate: false,
    exact: false,
  },

  /* Rich Menu */
  RICH_MENU: {
    key: 'richMenu',
    name: 'Rich Menu',
    path: '/:userId/rich-menu',
    exact: false,
  },
  RICH_MENU_DESIGN: {
    key: 'richMenuDesign',
    name: 'Rich Menu',
    path: '/:userId/rich-menu/design/:id?',
    exact: false,
  },
  RICH_MENU_EMBED: {
    key: 'richMenuEmbed',
    name: 'Rich menu embed',
    path: '/:userId/rich-menu/embed',
    exact: false,
  },

  /* Advanced Image Map */
  ADVANCED_IMAGE_MAP: {
    key: 'advancedImageMap',
    name: 'Advanced Imagemap',
    path: '/:userId/advanced-imagemap',
    exact: false,
  },
  ADVANCED_IMAGE_MAP_DESIGN: {
    key: 'advancedImageMapDesign',
    name: 'Advanced Imagemap',
    path: '/:userId/advanced-imagemap/design/:id?',
    exact: false,
  },
  ADVANCED_IMAGE_MAP_EMBED: {
    key: 'advancedImageMapEmbed',
    name: 'Advanced Imagemap embed',
    path: '/:userId/advanced-imagemap/embed',
    exact: false,
  },

  /* Media Json */
  MEDIA_JSON: {
    key: 'mediaJson',
    name: 'Media json',
    path: '/:userId/media-json',
    exact: false,
  },
  MEDIA_JSON_DESIGN: {
    key: 'mediaJsonDesign',
    name: 'Media json',
    path: '/:userId/media-json/design/:id?',
    exact: false,
  },
  MEDIA_JSON_EMBED: {
    key: 'mediaJsonEmbed',
    name: 'Media json embed',
    path: '/:userId/media-json/embed',
    exact: false,
  },

  /* Media Template */
  MEDIA_TEMPLATE: {
    key: 'mediaTemplate',
    name: 'Media template',
    path: '/:userId/media-template',
    exact: false,
  },
  MEDIA_TEMPLATE_DESIGN: {
    key: 'mediaTemplateDesign',
    name: 'Media template',
    path: '/:userId/media-template/design/:id?',
    exact: false,
  },
  MEDIA_TEMPLATE_EMBED: {
    key: 'mediaTemplateEmbed',
    name: 'Media template embed',
    path: '/:userId/media-template/embed',
    exact: false,
  },

  NOT_FOUND: {
    key: 'notFound',
    name: 'Not Found',
    path: '/not-found',
    exact: false,
    isPrivate: false,
  },
};
