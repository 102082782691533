import { useLocation, useHistory } from 'react-router-dom';

type SetSearchParams = (params: Record<string, string | undefined>) => void;
type DeleteSearchParam = (key: string) => void;

export const useSearchParams = (): [URLSearchParams, SetSearchParams, DeleteSearchParam] => {
  const location = useLocation();
  const history = useHistory();

  const setSearchParams: SetSearchParams = params => {
    const searchParams = new URLSearchParams(location.search);

    // Loop through the params object and set the search params
    for (const key in params) {
      if (params[key] !== undefined) {
        searchParams.set(key, params[key] as string);
      } else {
        searchParams.delete(key);
      }
    }

    // Construct the new URL with the updated search params
    const newPath = `${location.pathname}?${searchParams.toString()}`;

    // Use history to push the new path
    history.push(newPath);
  };

  const deleteSearchParam: DeleteSearchParam = key => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(key);

    const newPath = `${location.pathname}?${searchParams.toString()}`;
    history.push(newPath);
  };

  return [new URLSearchParams(location.search), setSearchParams, deleteSearchParam];
};
