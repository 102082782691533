// Libraries
import { ChangeEvent, FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';

// Components
import AntdInput, { InputProps as AntdInputProps } from 'antd/lib/input';
import { RequiredLabel, Text } from 'app/components/atoms';

// Css

// Utils
import { handleError } from 'app/utils/handleError';
import { getPreventKeyboardAction } from 'app/utils/web';

// Locales
import { translations } from 'locales/translations';

const PATH = 'src/app/components/atoms/Input/index.tsx';

export interface InputProps extends AntdInputProps {
  noborder?: 'true' | 'false' | boolean;
  debounce?: number;
  label?: ReactNode;
  onAfterChange?: (value: any) => void;
  errorArchive?: string;
  required?: boolean;
  focused?: boolean;
  errorMsg?: string;
  disableUndo?: boolean;
}

export const Input: FC<InputProps> = props => {
  // Props
  const { debounce, errorArchive, required, focused, label, onAfterChange, onChange, errorMsg, ...restProps } = props;

  // I18n
  const { t } = useTranslation();

  // State
  const [value, setValue] = useState<any>(props.value);
  const [isFocused, setFocused] = useState(false);

  const requiredMsg = useMemo(() => {
    let msg = '';
    const isEmptyValue = !props.value || (Array.isArray(props.value) && !props.value.length);

    if (required && isEmptyValue && isFocused) {
      msg = t(translations.messageError.fieldIsRequired.message);
    }

    return msg;
  }, [props.value, required, isFocused, t]);

  // Ref
  const timeoutAfterChange = useRef<any>(null);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (focused) {
      setFocused(focused);
    }
  }, [focused]);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { value } = event.target;

      setValue(value);
      onChange && onChange(event);

      if (timeoutAfterChange) {
        clearTimeout(timeoutAfterChange.current);
      }

      timeoutAfterChange.current = setTimeout(() => {
        onAfterChange && onAfterChange(value);
      }, debounce);
    } catch (error) {
      handleError(error, {
        path: PATH,
        name: 'onChangeInput',
        args: {},
      });
    }
  };

  const renderRequiredLabel = (label: ReactNode) => {
    if (required) {
      return (
        <RequiredLabel className="ants-mb-5px" color="#666666">
          {label}
        </RequiredLabel>
      );
    }

    return (
      <Text className="ants-mb-5px" color="#666666">
        {label}
      </Text>
    );
  };
  const listDisableActions: Parameters<typeof getPreventKeyboardAction>[0] = [];

  if (props.disableUndo) {
    listDisableActions.push('undo');
  }

  return (
    <div className="input__wrapper">
      {label && renderRequiredLabel(label)}

      <StyledInput
        {...restProps}
        value={value}
        onBlur={e => {
          if (!isFocused) {
            setFocused(true);
          }

          restProps.onBlur && restProps.onBlur(e);
        }}
        onChange={onChangeInput}
        {...getPreventKeyboardAction(listDisableActions)}
      />
      {restProps.status === 'error' || errorArchive || requiredMsg ? (
        <Text color="#ff4d4f" className="ants-ml-2 ants-mt-5px">
          {errorMsg || errorArchive || requiredMsg}
        </Text>
      ) : null}
    </div>
  );
};

Input.defaultProps = {
  debounce: 400,
};

export const StyledInput = styled(AntdInput)<InputProps>`
  ${tw`
  ants-h-30px ants-border-none ants-rounded-none ants-px-5px
  ants-font-roboto ants-text-normal 
  ants-shadow-cus-input 
  placeholder:ants-text-accent-5
  hover:ants-bg-blue-second
  ants-transition-all
  `}

  ${({ noborder }) => noborder && tw`ants-shadow-none ants-px-0`}

  &:active,
  &:focus {
    ${tw`ants-bg-blue-second ants-shadow-cus-input-active`}
  }

  &:disabled {
    ${tw`ants-bg-accent-1 ants-text-accent-5 ants-shadow-cus-input`}
  }
`;

export const TextArea = styled(AntdInput.TextArea)<{ showBorder?: boolean }>`
  ${tw`
  ants-h-30px ants-border-none ants-rounded-none ants-px-5px
  ants-font-roboto ants-text-normal 
  ants-shadow-cus-input 
  placeholder:ants-text-accent-5
  hover:ants-bg-blue-second
  ants-transition-all
  `}

  &:active,
  &:focus {
    ${tw`ants-bg-blue-second ants-shadow-cus-input-active`}
  }

  &:disabled {
    ${tw`ants-bg-accent-1 ants-text-accent-5 ants-shadow-cus-input`}
  }

  ${props =>
    props.showBorder &&
    css`
      border: 1px solid #e0e0e0;
    `}
`;
