// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';

export const TagWrapper = styled.div`
  .ant-tag {
    ${tw`ants-text-normal ants-flex ants-items-center ants-w-max `}
  }
  .tag-label {
    ${tw`ants-overflow-hidden ants-overflow-ellipsis`}
    max-width: 80px;
    color: #096dd9;
  }
`;
