// Libraries
import { ROUTES } from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

// Actions
import { layoutActions } from 'app/modules/slice';

// Utils
import { handleError } from 'app/utils/handleError';
import { getCookie } from 'app/utils/cookie';

// constants
import { APP_CONFIG } from 'constants/appConfig';

const PATH = 'app/components/common/PrivateRoute';

interface PrivateRouteProps extends RouteProps {}

export const PrivateRoute: React.FC<PrivateRouteProps> | any = props => {
  const dispatch = useDispatch();

  // Actions
  const actions = layoutActions;

  const [isValid, setValid] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = () => {
    try {
      const dataCookie = JSON.parse(decodeURIComponent(getCookie(APP_CONFIG.U_OGS)) || '{}') || {};

      if (!dataCookie || !dataCookie.token) {
        return setValid(false);
      }

      dispatch(
        actions.updateUser({
          isUserChecked: true,
          isAuthenticated: true,
          isAccessDenied: false,
          token: dataCookie.token,
          user_id: dataCookie.user_id,
          account_id: dataCookie.user_id,
        }),
      );

      setValid(true);
    } catch (error) {
      handleError(error, {
        path: PATH,
        name: 'validate',
        args: {},
      });

      setValid(false);
    }
  };

  if (isValid !== undefined) {
    if (!isValid) {
      if (APP_CONFIG.APPLICATION_ENV === 'development') {
        return <Redirect to={ROUTES.LOGIN.path} />;
      } else {
        return (window.location.href = APP_CONFIG.LOGIN_URL);
      }
    }

    return <Route {...props} />;
  }

  return null;
};

export default PrivateRoute;
