// Libraries
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, HashRouter as Router, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLayoutSlice } from './slice';
import { useDispatch } from 'react-redux';

import 'assets/js/cqfill.iife.min';

// Font-awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Components
import PrivateRoute from 'app/components/common/PrivateRoute';

// Routes
import rootRoutes from 'app/modules/routes';

// locales
import 'dayjs/locale/vi';

// Css
import { useUserInfo, useUserInfoV2 } from 'app/hooks';

// Constants
import { APP_CONFIG } from 'constants/appConfig';
import { ConfigProvider } from '@antscorp/antsomi-ui';
import { isEmpty } from 'lodash';

library.add(fas, far, fab);

export function App() {
  const dispatch = useDispatch();

  // Hooks
  const { i18n } = useTranslation();
  const { user_id, account_id, token } = useUserInfo();
  const userInfo = useUserInfoV2();

  // Actions
  const { actions } = useLayoutSlice();

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      dispatch(
        actions.updateUser({
          language: userInfo?.language,
        }),
      );

      if (i18n.language !== userInfo?.language) {
        i18n.changeLanguage(userInfo?.language || 'en');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // const urlLogout = `${window.location.origin}`;
  return (
    <ConfigProvider
      appConfig={{
        env: APP_CONFIG.APPLICATION_ENV as any,
        auth: {
          token: token ?? undefined,
          portalId: Number(APP_CONFIG.NETWORK_ID),
          userId: user_id ?? undefined,
          accountId: account_id ?? undefined,
        },
        languageCode: userInfo?.language || 'en',
        // urlLogout,
        // languageCode: userInfo.language || 'en',
      }}
      locale={(userInfo?.language as any) || 'en'}
    >
      <Router>
        <Helmet htmlAttributes={{ lang: i18n.language }}></Helmet>
        <Switch>
          {rootRoutes.map((route, index) => {
            const { exact, path, component, isPrivate, key } = route;

            if (isPrivate) {
              return <PrivateRoute key={key} path={path} exact={exact} component={component} />;
            }

            return <Route key={key} path={path} exact={exact} component={component} />;
          })}
          <Redirect to={'/'} />
        </Switch>
      </Router>
    </ConfigProvider>
  );
}
