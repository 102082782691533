// Hooks
import { useUserInfo } from './useUserInfo';

// Queries
import { useGetUserInfo } from 'app/queries/ThirdParty/useGetUserInfo';

export const useUserInfoV2 = () => {
  const { user_id } = useUserInfo();
  const { data: userInfo } = useGetUserInfo({
    userId: `${user_id}`,
  });

  return userInfo;
};
