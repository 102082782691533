import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface FormLabelProps extends React.HtmlHTMLAttributes<HTMLLabelElement> {
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
  fontWeight?: number | 'normal' | 'bold';
  margin?: number;
  padding?: number;
  color?: string;
  fontSize?: string;
  children?: ReactNode;
}

export const FormLabel: React.FC<FormLabelProps> = props => {
  const { textTransform, fontWeight, margin, padding, color, fontSize, children, ...rests } = props;
  return (
    <Wrapper style={{ textTransform, fontWeight, margin, padding, color, fontSize }} {...rests}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: ${p => p.theme.textSecondary};
  font-size: 0.75rem;
`;
