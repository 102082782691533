// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';

// Components
import { Tooltip } from 'antd';

export const StyledTooltip = styled(Tooltip)`
  ${tw`ants-flex ants-items-center`}
`;
