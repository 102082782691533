// Key code constants
// Available values for `KeyboardEvent.keyCode` attribute.
export const KEY_CANCEL = 3;
export const KEY_HELP = 6;
export const KEY_BACK_SPACE = 8;
export const KEY_TAB = 9;
export const KEY_CLEAR = 12;
export const KEY_RETURN = 13;
export const KEY_SHIFT = 16;
export const KEY_CONTROL = 17;
export const KEY_ALT = 18;
export const KEY_PAUSE = 19;
export const KEY_CAPS_LOCK = 20;
export const KEY_ESCAPE = 27;
export const KEY_SPACE = 32;
export const KEY_PAGE_UP = 33;
export const KEY_PAGE_DOWN = 34;
export const KEY_END = 35;
export const KEY_HOME = 36;
export const KEY_LEFT = 37;
export const KEY_UP = 38;
export const KEY_RIGHT = 39;
export const KEY_DOWN = 40;
export const KEY_PRINTSCREEN = 44;
export const KEY_INSERT = 45;
export const KEY_DELETE = 46;
export const KEY_0 = 48;
export const KEY_1 = 49;
export const KEY_2 = 50;
export const KEY_3 = 51;
export const KEY_4 = 52;
export const KEY_5 = 53;
export const KEY_6 = 54;
export const KEY_7 = 55;
export const KEY_8 = 56;
export const KEY_9 = 57;
export const KEY_A = 65;
export const KEY_B = 66;
export const KEY_C = 67;
export const KEY_D = 68;
export const KEY_E = 69;
export const KEY_F = 70;
export const KEY_G = 71;
export const KEY_H = 72;
export const KEY_I = 73;
export const KEY_J = 74;
export const KEY_K = 75;
export const KEY_L = 76;
export const KEY_M = 77;
export const KEY_N = 78;
export const KEY_O = 79;
export const KEY_P = 80;
export const KEY_Q = 81;
export const KEY_R = 82;
export const KEY_S = 83;
export const KEY_T = 84;
export const KEY_U = 85;
export const KEY_V = 86;
export const KEY_W = 87;
export const KEY_X = 88;
export const KEY_Y = 89;
export const KEY_Z = 90;
export const KEY_LEFT_CMD = 91;
export const KEY_RIGHT_CMD = 92;
export const KEY_CONTEXT_MENU = 93;
export const KEY_NUMPAD0 = 96;
export const KEY_NUMPAD1 = 97;
export const KEY_NUMPAD2 = 98;
export const KEY_NUMPAD3 = 99;
export const KEY_NUMPAD4 = 100;
export const KEY_NUMPAD5 = 101;
export const KEY_NUMPAD6 = 102;
export const KEY_NUMPAD7 = 103;
export const KEY_NUMPAD8 = 104;
export const KEY_NUMPAD9 = 105;
export const KEY_MULTIPLY = 106;
export const KEY_ADD = 107;
export const KEY_SUBTRACT = 109;
export const KEY_DECIMAL = 110;
export const KEY_DIVIDE = 111;
export const KEY_F1 = 112;
export const KEY_F2 = 113;
export const KEY_F3 = 114;
export const KEY_F4 = 115;
export const KEY_F5 = 116;
export const KEY_F6 = 117;
export const KEY_F7 = 118;
export const KEY_F8 = 119;
export const KEY_F9 = 120;
export const KEY_F10 = 121;
export const KEY_F11 = 122;
export const KEY_F12 = 123;
export const KEY_F13 = 124;
export const KEY_F14 = 125;
export const KEY_F15 = 126;
export const KEY_F16 = 127;
export const KEY_F17 = 128;
export const KEY_F18 = 129;
export const KEY_F19 = 130;
export const KEY_F20 = 131;
export const KEY_F21 = 132;
export const KEY_F22 = 133;
export const KEY_F23 = 134;
export const KEY_F24 = 135;
export const KEY_NUM_LOCK = 144;
export const KEY_SCROLL_LOCK = 145;
export const KEY_SEMICOLON = 186;
export const KEY_EQUALS = 187;
export const KEY_COMMA = 188;
export const KEY_DASH = 189;
export const KEY_PERIOD = 190;
export const KEY_SLASH = 191;
export const KEY_BACK_QUOTE = 192;
export const KEY_OPEN_BRACKET = 219;
export const KEY_BACK_SLASH = 220;
export const KEY_CLOSE_BRACKET = 221;
export const KEY_QUOTE = 222;
export const KEY_IME = 229;

// Key code constants specific to firefox only.
export const KEY_FIREFOX_ENTER = 14;
export const KEY_FIREFOX_SEMICOLON = 59;
export const KEY_FIREFOX_EQUALS = 61;
export const KEY_FIREFOX_SEPARATOR = 108;
export const KEY_FIREFOX_META = 224;

// Key values constants
// Available values for `KeyboardEvent.key` attribute.
export const VALUE_CANCEL = 'Cancel';
export const VALUE_HELP = 'Help';
export const VALUE_BACK_SPACE = 'Backspace';
export const VALUE_TAB = 'Tab';
export const VALUE_CLEAR = 'Clear';
export const VALUE_ENTER = 'Enter';
export const VALUE_RETURN = 'Enter';
export const VALUE_SHIFT = 'Shift';
export const VALUE_CONTROL = 'Control';
export const VALUE_ALT = 'Alt';
export const VALUE_PAUSE = 'Pause';
export const VALUE_CAPS_LOCK = 'CapsLock';
export const VALUE_ESCAPE = 'Escape';
export const VALUE_SPACE = ' ';
export const VALUE_PAGE_UP = 'PageUp';
export const VALUE_PAGE_DOWN = 'PageDown';
export const VALUE_END = 'End';
export const VALUE_HOME = 'Home';
export const VALUE_LEFT = 'ArrowLeft';
export const VALUE_UP = 'ArrowUp';
export const VALUE_RIGHT = 'ArrowRight';
export const VALUE_DOWN = 'ArrowDown';
export const VALUE_PRINTSCREEN = 'PrintScreen';
export const VALUE_INSERT = 'Insert';
export const VALUE_DELETE = 'Delete';
export const VALUE_0 = '0';
export const VALUE_1 = '1';
export const VALUE_2 = '2';
export const VALUE_3 = '3';
export const VALUE_4 = '4';
export const VALUE_5 = '5';
export const VALUE_6 = '6';
export const VALUE_7 = '7';
export const VALUE_8 = '8';
export const VALUE_9 = '9';
export const VALUE_A = 'a';
export const VALUE_B = 'b';
export const VALUE_C = 'c';
export const VALUE_D = 'd';
export const VALUE_E = 'e';
export const VALUE_F = 'f';
export const VALUE_G = 'g';
export const VALUE_H = 'h';
export const VALUE_I = 'i';
export const VALUE_J = 'j';
export const VALUE_K = 'k';
export const VALUE_L = 'l';
export const VALUE_M = 'm';
export const VALUE_N = 'n';
export const VALUE_O = 'o';
export const VALUE_P = 'p';
export const VALUE_Q = 'q';
export const VALUE_R = 'r';
export const VALUE_S = 's';
export const VALUE_T = 't';
export const VALUE_U = 'u';
export const VALUE_V = 'v';
export const VALUE_W = 'w';
export const VALUE_X = 'x';
export const VALUE_Y = 'y';
export const VALUE_Z = 'z';
export const VALUE_A_UPPER = 'A';
export const VALUE_B_UPPER = 'B';
export const VALUE_C_UPPER = 'C';
export const VALUE_D_UPPER = 'D';
export const VALUE_E_UPPER = 'E';
export const VALUE_F_UPPER = 'F';
export const VALUE_G_UPPER = 'G';
export const VALUE_H_UPPER = 'H';
export const VALUE_I_UPPER = 'I';
export const VALUE_J_UPPER = 'J';
export const VALUE_K_UPPER = 'K';
export const VALUE_L_UPPER = 'L';
export const VALUE_M_UPPER = 'M';
export const VALUE_N_UPPER = 'N';
export const VALUE_O_UPPER = 'O';
export const VALUE_P_UPPER = 'P';
export const VALUE_Q_UPPER = 'Q';
export const VALUE_R_UPPER = 'R';
export const VALUE_S_UPPER = 'S';
export const VALUE_T_UPPER = 'T';
export const VALUE_U_UPPER = 'U';
export const VALUE_V_UPPER = 'V';
export const VALUE_W_UPPER = 'W';
export const VALUE_X_UPPER = 'X';
export const VALUE_Y_UPPER = 'Y';
export const VALUE_Z_UPPER = 'Z';
export const VALUE_META = 'Meta';
export const VALUE_LEFT_CMD = 'Meta';
export const VALUE_RIGHT_CMD = 'Meta';
export const VALUE_LEFT_WINDOW = 'left window key';
export const VALUE_RIGHT_WINDOW = 'right window key';
export const VALUE_CONTEXT_MENU = 'ContextMenu';
export const VALUE_NUMPAD0 = '0';
export const VALUE_NUMPAD1 = '1';
export const VALUE_NUMPAD2 = '2';
export const VALUE_NUMPAD3 = '3';
export const VALUE_NUMPAD4 = '4';
export const VALUE_NUMPAD5 = '5';
export const VALUE_NUMPAD6 = '6';
export const VALUE_NUMPAD7 = '7';
export const VALUE_NUMPAD8 = '8';
export const VALUE_NUMPAD9 = '9';
export const VALUE_MULTIPLY = '*';
export const VALUE_ADD = '+';
export const VALUE_SUBTRACT = '-';
export const VALUE_DECIMAL = '.';
export const VALUE_DIVIDE = '/';
export const VALUE_F1 = 'F1';
export const VALUE_F2 = 'F2';
export const VALUE_F3 = 'F3';
export const VALUE_F4 = 'F4';
export const VALUE_F5 = 'F5';
export const VALUE_F6 = 'F6';
export const VALUE_F7 = 'F7';
export const VALUE_F8 = 'F8';
export const VALUE_F9 = 'F9';
export const VALUE_F10 = 'F10';
export const VALUE_F11 = 'F11';
export const VALUE_F12 = 'F12';
export const VALUE_F13 = 'F13';
export const VALUE_F14 = 'F14';
export const VALUE_F15 = 'F15';
export const VALUE_F16 = 'F16';
export const VALUE_F17 = 'F17';
export const VALUE_F18 = 'F18';
export const VALUE_F19 = 'F19';
export const VALUE_F20 = 'F20';
export const VALUE_F21 = 'F21';
export const VALUE_F22 = 'F22';
export const VALUE_F23 = 'F23';
export const VALUE_F24 = 'F24';
export const VALUE_NUM_LOCK = 'NumLock';
export const VALUE_SCROLL_LOCK = 'ScrollLock';
export const VALUE_SEMICOLON = ';';
export const VALUE_EQUALS = '=';
export const VALUE_COMMA = ',';
export const VALUE_DASH = '-';
export const VALUE_PERIOD = '.';
export const VALUE_SLASH = '/';
export const VALUE_BACK_QUOTE = '`';
export const VALUE_OPEN_BRACKET = '[';
export const VALUE_BACK_SLASH = '\\';
export const VALUE_CLOSE_BRACKET = ']';
export const VALUE_QUOTE = "'";

// Key code constants
// Available values for `KeyboardEvent.code` attribute.
export const CODE_UNIDENTIFIED = 'Unidentified';
export const CODE_ESCAPE = 'Escape';
export const CODE_MINUS = 'Minus';
export const CODE_DASH = 'Minus';
export const CODE_EQUALS = 'Equal';
export const CODE_BACK_SPACE = 'Backspace';
export const CODE_TAB = 'Tab';
export const CODE_ENTER = 'Enter';
export const CODE_RETURN = 'Enter';
export const CODE_SHIFT_LEFT = 'ShiftLeft';
export const CODE_SHIFT_RIGHT = 'ShiftRight';
export const CODE_CONTROL_LEFT = 'ControlLeft';
export const CODE_CONTROL_RIGHT = 'ControlRight';
export const CODE_ALT_LEFT = 'AltLeft';
export const CODE_ALT_RIGHT = 'AltRight';
export const CODE_PAUSE = 'Pause';
export const CODE_CAPS_LOCK = 'CapsLock';
export const CODE_SPACE = 'Space';
export const CODE_PAGE_UP = 'PageUp';
export const CODE_PAGE_DOWN = 'PageDown';
export const CODE_END = 'End';
export const CODE_HOME = 'Home';
export const CODE_LEFT = 'ArrowLeft';
export const CODE_UP = 'ArrowUp';
export const CODE_RIGHT = 'ArrowRight';
export const CODE_DOWN = 'ArrowDown';
export const CODE_PRINTSCREEN = 'PrintScreen';
export const CODE_INSERT = 'Insert';
export const CODE_DELETE = 'Delete';
export const CODE_0 = 'Digit0';
export const CODE_1 = 'Digit1';
export const CODE_2 = 'Digit2';
export const CODE_3 = 'Digit3';
export const CODE_4 = 'Digit4';
export const CODE_5 = 'Digit5';
export const CODE_6 = 'Digit6';
export const CODE_7 = 'Digit7';
export const CODE_8 = 'Digit8';
export const CODE_9 = 'Digit9';
export const CODE_A = 'KeyA';
export const CODE_B = 'KeyB';
export const CODE_C = 'KeyC';
export const CODE_D = 'KeyD';
export const CODE_E = 'KeyE';
export const CODE_F = 'KeyF';
export const CODE_G = 'KeyG';
export const CODE_H = 'KeyH';
export const CODE_I = 'KeyI';
export const CODE_J = 'KeyJ';
export const CODE_K = 'KeyK';
export const CODE_L = 'KeyL';
export const CODE_M = 'KeyM';
export const CODE_N = 'KeyN';
export const CODE_O = 'KeyO';
export const CODE_P = 'KeyP';
export const CODE_Q = 'KeyQ';
export const CODE_R = 'KeyR';
export const CODE_S = 'KeyS';
export const CODE_T = 'KeyT';
export const CODE_U = 'KeyU';
export const CODE_V = 'KeyV';
export const CODE_W = 'KeyW';
export const CODE_X = 'KeyX';
export const CODE_Y = 'KeyY';
export const CODE_Z = 'KeyZ';
export const CODE_META_LEFT = 'MetaLeft';
export const CODE_OS_LEFT = 'OSLeft';
export const CODE_META_RIGHT = 'MetaRight';
export const CODE_OS_RIGHT = 'OSRight';
export const CODE_CONTEXT_MENU = 'ContextMenu';
export const CODE_NUMPAD0 = 'Numpad0';
export const CODE_NUMPAD1 = 'Numpad1';
export const CODE_NUMPAD2 = 'Numpad2';
export const CODE_NUMPAD3 = 'Numpad3';
export const CODE_NUMPAD4 = 'Numpad4';
export const CODE_NUMPAD5 = 'Numpad5';
export const CODE_NUMPAD6 = 'Numpad6';
export const CODE_NUMPAD7 = 'Numpad7';
export const CODE_NUMPAD8 = 'Numpad8';
export const CODE_NUMPAD9 = 'Numpad9';
export const CODE_NUMPAD_MULTIPLY = 'NumpadMultiply';
export const CODE_NUMPAD_ADD = 'NumpadAdd';
export const CODE_NUMPAD_SUBTRACT = 'NumpadSubtract';
export const CODE_NUMPAD_DECIMAL = 'NumpadDecimal';
export const CODE_NUMPAD_DIVIDE = 'NumpadDivide';
export const CODE_NUMPAD_ENTER = 'NumpadEnter';
export const CODE_F1 = 'F1';
export const CODE_F2 = 'F2';
export const CODE_F3 = 'F3';
export const CODE_F4 = 'F4';
export const CODE_F5 = 'F5';
export const CODE_F6 = 'F6';
export const CODE_F7 = 'F7';
export const CODE_F8 = 'F8';
export const CODE_F9 = 'F9';
export const CODE_F10 = 'F10';
export const CODE_F11 = 'F11';
export const CODE_F12 = 'F12';
export const CODE_F13 = 'F13';
export const CODE_F14 = 'F14';
export const CODE_F15 = 'F15';
export const CODE_F16 = 'F16';
export const CODE_F17 = 'F17';
export const CODE_F18 = 'F18';
export const CODE_F19 = 'F19';
export const CODE_F20 = 'F20';
export const CODE_F21 = 'F21';
export const CODE_F22 = 'F22';
export const CODE_F23 = 'F23';
export const CODE_F24 = 'F24';
export const CODE_NUM_LOCK = 'NumLock';
export const CODE_SCROLL_LOCK = 'ScrollLock';
export const CODE_SEMICOLON = 'Semicolon';
export const CODE_COMMA = 'Comma';
export const CODE_PERIOD = 'Period';
export const CODE_SLASH = 'Slash';
export const CODE_BACK_QUOTE = 'Backquote';
export const CODE_OPEN_BRACKET = 'BracketLeft';
export const CODE_BACK_SLASH = 'Backslash';
export const CODE_CLOSE_BRACKET = 'BracketRight';
export const CODE_QUOTE = 'Quote';
