// Libraries
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Utils
import { handleError } from 'app/utils/handleError';

const PATH = 'src/app/hooks/useTabs.ts';

interface useTabsProps {
  tabMenu?: string[];
  defaultTab?: string;
}

/**
 * @param tabMenu: list tab menu using to check whether 'activeTab' is in tab menu list or not
 * @param defaultTab: Returns by default tab if no tab matches the value in the tabMenu or no active tab is found
 */
export const useTabs = (props: useTabsProps) => {
  const history = useHistory();
  const location = useLocation();

  const { tabMenu, defaultTab } = props;
  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const currentTab = searchParams.get('tab')?.toString();
  const activeTab = tabMenu ? (tabMenu.includes(currentTab || '') ? currentTab : defaultTab) : currentTab || defaultTab;

  const onChangeTab = useCallback(
    (nextTab: string) => {
      try {
        searchParams.set('tab', nextTab);
        history.push({ search: searchParams.toString() });
      } catch (error) {
        handleError(error, {
          path: PATH,
          name: onChangeTab.name,
          args: { nextTab },
        });
      }
    },
    [searchParams, history],
  );

  return { onChangeTab, activeTab: activeTab || '' };
};
