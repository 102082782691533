/**
 *
 * Asynchronously loads the component for Public
 *
 */
import { lazyLoad } from 'utils/loadable';

export const Public = lazyLoad(
  () => import('./index'),
  module => module.Public,
);
