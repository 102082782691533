/* eslint-disable no-console */
// Libraries
import React, { ErrorInfo, ReactNode } from 'react';
import { withTranslation, Trans } from 'react-i18next';

// Atoms
import { Button } from 'app/components/atoms';

// Locales
import { translations } from 'locales/translations';

// Constants
import { APP_CONFIG } from 'constants/appConfig';
import { handleError } from 'app/utils/handleError';

interface Props {
  children?: ReactNode;
  t?: any;
}

interface State {
  hasError: boolean;
  counter: number;
}

let interval: any;

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    counter: 10,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, counter: 10 };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    handleError(error, { from: 'error-boundary', priority: 'high' }, false);

    if (APP_CONFIG.APPLICATION_ENV !== 'development') {
      interval = setInterval(() => {
        if (this.state.counter <= 1) {
          clearInterval(interval);
          window.location.reload();
        }

        this.setState(state => ({ counter: state.counter - 1 }));
      }, 1000);
    }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          style={{ height: '100vh' }}
          className="ants-flex ants-items-center ants-flex-col ants-justify-center ants-text-center"
        >
          <h1>
            <Trans i18nKey={translations.errorBoundary.message} values={{ second: this.state.counter }} />
          </h1>
          <Button className="ants-mt-2.5" onClick={() => window.location.reload()}>
            {this.props.t(translations.errorBoundary.button)}
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
