// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';
interface TextWrapperProps {
  size?: 'small' | 'medium' | 'large' | number;
  children?: React.ReactNode;
}

export const TextWrapper = styled.div<TextWrapperProps>`
  ${tw`ants-text-cus-base ants-text-normal ants-font-roboto ants-transition-colors`}

  ${p => {
    switch (p.size) {
      case 'small':
        return tw`ants-text-normal`;

      case 'medium':
        return tw`ants-text-medium`;

      case 'large':
        return tw`ants-text-large`;

      default:
        return `
          font-size: ${p.size}px;
        `;
    }
  }}

  &.--disabled {
    ${tw`ants-text-cus-disable ants-pointer-events-none`}
  }

  &.--secondary {
    ${tw`ants-text-cus-second`}
  }

  &.--error {
    ${tw`ants-text-cus-error`}
  }

  &.--warning {
    ${tw`ants-text-cus-warning`}
  }
`;
