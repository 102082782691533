import { useLocation } from 'react-router-dom';

type Route = { key: string; name: string; path: string; exact: boolean; isPrivate?: boolean };
type Routes = Record<string, Route>;

export const useRouteInfo = (
  routes: Routes,
): { routeInfo: Route | undefined; routePattern: string; isDetailPage: boolean } => {
  const location = useLocation();

  // let routePattern = location?.pathname || '';
  const splitPath = location?.pathname?.split('/') || [];

  const routesList = Object.values(routes);
  const filterRoute = routesList
    .map(item => item.path.split('/'))
    .filter(item => item.length === splitPath.length)
    .sort((a, b) => {
      let countA = 0;
      let countB = 0;
      for (let index = 0; index < splitPath.length; index++) {
        // const element = splitPath[index];
        if (a[index] === splitPath[index]) countA++;
        if (b[index] === splitPath[index]) countB++;
      }
      return countB - countA;
    })[0];

  const routePattern = filterRoute.join('/');

  const routeInfo = routesList.find(item => item.path === routePattern);

  return { routeInfo, routePattern, isDetailPage: filterRoute[filterRoute.length - 1].includes(':') };
};
