// Polyfill
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Libraries
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

// Css
import 'animate.css';
import 'sanitize.css/sanitize.css';
import 'assets/css/antd.less';
import 'assets/css/global.scss';

import { App } from 'app/modules';

import { configureAppStore } from 'store/configureStore';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';

// Initialize service
import { injectStore } from './app/services';
import ErrorBoundary from 'app/components/common/ErrorBoundary';
// import { ConfigProvider } from '@antscorp/antsomi-ui';

// // Fonts
// WebFont.load({
//   classes: false,
//   events: false,
//   google: {
//     families: [
//       'Sacramento:400,700',
//       'Abril Fatface:400',
//       'Aleo:300,400,700',
//       'Arvo:400,700',
//       'Bitter:100,200,300,400,500,600,700,800,900',
//       'Bree Serif:400',
//       'Cabin:400,500,600,700',
//       'Cookie:400',
//       'Delius Swash Caps:400',
//       'Dosis:200,300,400,500,600,700,800',
//       'Droid Sans:400,700',
//       'Droid Serif:400,700',
//       'EB Garamond:400,500,600,700,800',
//       'Josefin Slab:100,200,300,400,500,600,700',
//       'Just Another Hand:400',
//       'Lakki Reddy:400',
//       'Lato:100,300,400,700,900',
//       'Libre Baskerville:400,700',
//       'Lobster:400',
//       'Lora:400,500,600,700',
//       'Mali:200,300,400,500,600,700',
//       'Merriweather:300,400,700,900',
//       'Montserrat:100,200,300,400,500,600,700,800,900',
//       'Noto Sans:400,700',
//       'Noto Serif:400,700',
//       'Nunito:200,300,400,500,600,700,800,900',
//       'Open Sans:300,400,500,600,700,800',
//       'Oswald:200,300,400,500,600,700',
//       'Poppins:100,200,300,400,500,600,700,800,900',
//       'PT Sans:400,700',
//       'PT Serif:400,700',
//       'Pinyon Script:400',
//       'Playfair Display:400,500,600,700,800,900',
//       'Quicksand:300,400,500,600,700',
//       'Raleway:100,200,300,400,500,600,700,800,900',
//       'Righteous:400',
//       'Roboto Slab:100,200,300,400,500,600,700,800,900',
//       'Roboto:100,300,400,500,700,900',
//       'Rubik:300,400,500,600,700,800,900',
//       'Sarabun:100,200,300,400,500,600,700,800',
//       'Source Sans Pro:200,300,400,600,700,900',
//       'Ubuntu:300,400,500,700',
//       'Vollkorn:400,500,600,700,800,900&display=swap',
//     ],
//   },
//   timeout: 2000,
// });

// Disable react-beautiful dnd dev warnings
window['__react-beautiful-dnd-disable-dev-warnings'] = true;

export const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE);

injectStore(store);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: 1,
    },
  },
});

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <HelmetProvider>
          {/* <React.StrictMode> */}
          <ErrorBoundary>
            {/* <ConfigProvider> */}
            <App />
            {/* </ConfigProvider> */}
          </ErrorBoundary>
          {/* </React.StrictMode> */}
        </HelmetProvider>
      </ThemeProvider>

      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
