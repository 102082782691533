import { configureStore, getDefaultMiddleware, StoreEnhancer } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { createTrackedSelector } from 'react-tracked';
import { useSelector } from 'react-redux';

import { createReducer } from './reducers';
import { RootState } from 'types';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(),
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      ...middlewares,
    ],
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== 'production' || process.env.PUBLIC_URL.length > 0,
    enhancers,
  });

  return store;
}

export const useTrackedSelector = createTrackedSelector(useSelector);

export const useOptimizeSelector = <T extends unknown>(selectFn: (state: RootState) => T) => {
  const state = useTrackedSelector() as RootState;
  return selectFn(state);
};

export const createSagaAction = (type: string, payload: any = {}) => {
  return {
    type,
    payload,
  };
};
