// Types
import { CSSProperties } from 'react';
import { TAlign } from 'app/modules/Dashboard/containers/MediaTemplate/containers/Design/types';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

// Utils
import { handleError } from './handleError';
import { REGEX } from 'constants/regex';
import { isObject } from 'lodash';

const PATH = 'app/utils/common';

/***
 * Get a property of object safely
 * @param fn: function that gets object property
 * @param defaultValue: default value if something wrong
 * @example
 *  getSafe(() => a.b.c.d, 'someValue') => d
 *  getSafe(() => a.b[undefined].c.d, 'someValue') => 'someValue'
 * @returns {string|*}
 */
export const getObjSafely = (fn, defaultValue = '') => {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
};
/**
 * Function to reorder list
 * @param {Object[]} list - list of item want to reorder
 * @param {number} startIndex - start item index want to replace position: ;
 * @param {number} endIndex - end item index want to replace position: ;
 * @returns {object[]}
 */
export const reorder = (
  list: Array<Record<string, unknown> | string | number>,
  startIndex: number,
  endIndex: number,
): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Function to compare 2 string with ignore case sensitive
 * @param {string} firstString - First string
 * @param {string} secondString - Second string
 * @returns {boolean}
 */
export const compareIgnoreCaseSensitive = (firstString: string, secondString: string): boolean => {
  return firstString.toLowerCase().trim() === secondString.toLowerCase().trim();
};

// Check if `child` is a descendant of `parent`
export const isDescendant = (parent: any, child: any): boolean => {
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }

    // Traverse up to the parent
    node = node.parentNode;
  }

  // Go up until the root but couldn't find the `parent`
  return false;
};

export const isEmail = (email: string) => {
  try {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'isEmail',
      args: {
        email,
      },
    });
  }
};

export const isUrl = (url: string) => {
  try {
    let re = REGEX.URL;

    return re.test(String(url).toLowerCase());
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'isUrl',
      args: {
        url,
      },
    });
  }
};

export const checkJSON = _str => {
  try {
    let result = true;

    try {
      JSON.parse(_str);
    } catch (e) {
      result = false;
    }

    return result;
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'checkJSON',
      args: {
        _str,
      },
    });
  }
};

export const snakeToCamel = str => {
  try {
    const isSnake = str.toString().includes('_');

    if (!isSnake) {
      return str;
    } else {
      return str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
    }
  } catch (error) {
    return str;
  }
};

export function camelToSnake(string) {
  return string
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
}

export const recursiveTransformData = (
  el,
  option = {
    transformStringToNumber: true,
  },
) => {
  try {
    if (!['null', 'undefined'].includes((typeof el).toString())) {
      if (Array.isArray(el)) {
        return el.map(item => recursiveTransformData(item));
      } else if (typeof el === 'string') {
        if (isNaN(+el) || (!isNaN(+el) && +el > Number.MAX_SAFE_INTEGER)) {
          // String
          if (checkJSON(el)) {
            const parseEl = JSON.parse(el);

            if (!!parseEl && (typeof parseEl !== 'number' || parseEl < Number.MAX_SAFE_INTEGER)) {
              if (Array.isArray(parseEl)) {
                return recursiveTransformData(parseEl);
              } else {
                return Object.keys(parseEl).reduce((initObj, key) => {
                  initObj[snakeToCamel(key)] = recursiveTransformData(parseEl[key]);

                  return initObj;
                }, {});
              }
            } else {
              return el;
            }
          } else {
            return el;
          }
        } else if (el.length && option.transformStringToNumber) {
          // Number
          return +el;
        } else {
          // Empty string
          return el;
        }
      } else if (typeof el === 'number') {
        return el;
      } else if (el && Object.keys(el).length) {
        return Object.keys(el).reduce((initObj, key) => {
          initObj[snakeToCamel(key)] = recursiveTransformData(el[key]);

          return initObj;
        }, {});
      }
    }

    return el;
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'recursiveTransformData',
      args: { ...el },
    });
  }
};

/**
 * Function to generate random string
 * @param number - length to render random
 * @returns {string}
 */
export const random = (number: number): string => {
  try {
    let text = '';
    let possible = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < number; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'random',
      args: {
        number,
      },
    });

    return '';
  }
};

/**
 * Function to generate unique random string
 * @param number - length to render random
 * @param arrRandom - arr current random to check unique
 * @returns {string}
 */
export const uniqueRandom = (number: number, arrRandom: string[]): string => {
  let randomString = random(number);
  let isExist = arrRandom.includes(randomString);

  while (isExist) {
    randomString = random(number);

    isExist = arrRandom.includes(randomString);
  }

  return randomString;
};

export const getNumberFromString = (str: string) => {
  try {
    return +(str.match(/^-?\d+/) || []).flat()[0];
  } catch (error) {
    return 0;
  }
};

/**
 * Function to remove accent
 * @param str - String want to remove accent
 * @returns {string} - Result after remove accent
 */
export const removeAccent = (str: string) => {
  try {
    str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
    str = str.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
    str = str.replace(/[ìíịỉĩ]/g, 'i');
    str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
    str = str.replace(/[ùúụủũưừứựửữ]/g, 'u');
    str = str.replace(/[ỳýỵỷỹ]/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, 'A');
    str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, 'E');
    str = str.replace(/[ÌÍỊỈĨ]/g, 'I');
    str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    return str;
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'removeAccent',
      args: {
        str,
      },
    });
  }
};

/**
 * Function to gen string to id/class of element
 * @param str - String want gen to id/class
 * @returns {string}
 */
export const genClassIdElement = (str: string) => {
  return removeAccent(str)
    ?.trim() // Trim string
    .replace(/[^\w -]/g, '') // Remove special world
    .replace(/\s+/g, '-') // Replace space to -
    .replace(/^\d+/g, ''); // Remove string start with number
};

/**
 * Function to get margin (left, right) from marginAlign
 * @param {string} marginAlign - align item
 * @param {object} styles - CSSProperties
 * @returns {string} - CSSProperties
 */
export const getMarginFromMarginAlign = (marginAlign: TAlign, styles: CSSProperties): CSSProperties => {
  return {
    marginLeft: marginAlign === 'right' || marginAlign === 'center' ? 'auto' : styles.marginLeft,
    marginRight: marginAlign === 'left' || marginAlign === 'center' ? 'auto' : styles.marginRight,
  };
};

export const randomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

/**
 * Function to upperCase first character
 * @param {string} string - string input
 * @returns {string} - string with upperCase at first character
 */
export const toCapitalizeCase = (string: string = ''): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

//capitalize only the first letter of the string.
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

//capitalize all words of a string.
export function capitalizeWords(string: string) {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

export const getLinkFromKey = (key, params): string => {
  try {
    let url = '';

    if (key && params) {
      Object.values(ROUTES).forEach((route: any) => {
        if (route.key && route.key === key) {
          let path = route.path;

          path = path && generatePath(path, params);

          url = path;
        }
      });
    }

    return url;
  } catch (error) {
    handleError(error, {
      path: PATH,
      name: 'removeAccent',
      args: {
        key,
        params,
      },
    });
    return '';
  }
};

export const parseFloatAdvanced = (number: Number, val: number) => {
  let str = '';

  str = number.toString();
  str = str.slice(0, str.indexOf('.') + val + 1);
  return Number(str);
};

/**
 * Function to flat array
 * @param {Array} tree - tree data
 * @param {string} keyFlat = 'id' - key to flat
 * @param {number} level = 0 - level of tree
 * @returns {Array} - tree data with children
 */
export const flattenTree = (tree: Array<any> = [], keyFlat = 'children', level?: number): Array<any> => {
  return tree.flatMap(item =>
    item[keyFlat] && level !== 0 ? [item, ...flattenTree(item[keyFlat], keyFlat, (level || 0) - 1)] : item,
  );
};

/**
 * Function to active menu
 * @param {string} top - top menu code
 * @param {Array} left = left menu code
 * @returns {void}
 */
export const activeMenu = ({ top = '', left = [] } = {}) => {
  try {
    const postMessageData = {
      eventType: 'ACTIVE-MENU-TOP-LEFT',
      header: '',
      left: [],
    };

    if (top) {
      postMessageData.header = top;
    }

    if (left) {
      postMessageData.left = left;
    }

    window.postMessage(postMessageData);
  } catch (error) {
    //
  }
};

/**
 * Function to get youtube id from url
 * @param {string} url - video url
 * @returns {string}
 */
export const parseURLYoutubteToVideoId = (url: string): string => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  }
  return '';
};
export function serializeLabelToCode(name: string) {
  // console.log('name', name);
  return name
    .toLocaleLowerCase()
    .replace(/ /g, '_')
    .replace(/[^\w]/g, '')
    .replace(/^(\d|aud|sgmt|_)+/g, '');
}
export const isNullish = (value: any): boolean => {
  return value === null || value === undefined;
};

/**
 * Maps a number to its corresponding ordinal text representation.
 * @param {number} num - The number to be mapped.
 * @return {string} The ordinal text representation of the number.
 */
export function mapNumberToOrdinalText(num: number): string {
  switch (num) {
    case 0:
      return 'zeroth';
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'fifth';
    case 6:
      return 'sixth';
    case 7:
      return 'seventh';
    case 8:
      return 'eighth';
    case 9:
      return 'ninth';
    case 10:
      return 'tenth';
    default:
      return '';
  }
}

/**
 * Adds a suffix number to a name if it already exists in a list of names.
 * @param {string} name - The name to be added to the list.
 * @param {Array<string>} namesList - An array of names to check against.
 * @returns {string} The original name if it is unique, or the name with a suffix number added to it if it already exists in the list.
 */
export function addSuffixToName(name: string, namesList: string[], separator = '_') {
  let suffix = 1;
  let newName = name;

  while (namesList.includes(newName)) {
    newName = `${name}${separator}${suffix}`;
    suffix++;
  }

  return newName;
}

/**
 * Merges the properties of the source object into the target object recursively.
 *
 * @param {object} target - The target object to merge into.
 * @param {object} source - The source object to merge.
 * @return {object} - The merged object.
 */
export const merge = (target, source) => {
  Object.keys(source).forEach(key => {
    if (isObject(source[key])) Object.assign(source[key], merge(target[key], source[key]));
  });
  Object.assign(target || {}, source);
  return target;
};

export const initialsErrorMessage = (name: string = 'name'): string => `The ${name} field is required.`;

export const checkNameIsExist = (name: string, namesList: string[]) => {
  return namesList.some(item => item === name);
};

/**
 * Recursively compares two objects and returns an object containing only the differing key-value pairs.
 * @param obj1 The first object to compare.
 * @param obj2 The second object to compare.
 * @returns An object containing the differing key-value pairs between obj1 and obj2.
 */
export function getDifferentKeyValue(obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any> {
  // Initialize an empty object to store the differing key-value pairs
  const result: Record<string, any> = {};

  // Iterate through each key in obj1
  for (const key in obj1) {
    // Check if the key is a direct property of obj1
    if (obj1.hasOwnProperty(key)) {
      // Check if both values at this key are objects (nested objects)
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        // Check if both values at this key are arrays
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          // If both values are arrays, compare their string representations
          // and add the key-value pair to the result if they are different
          if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
            result[key] = obj1[key];
          }
        } else {
          // If values are objects (but not arrays), recursively call getDifferentKeyValue
          // to compare nested objects
          const nestedDiff = getDifferentKeyValue(obj1[key], obj2[key]);
          // If nestedDiff contains differing key-value pairs, add them to the result
          if (Object.keys(nestedDiff).length > 0) {
            result[key] = nestedDiff;
          }
        }
      } else {
        // If values are not objects (i.e., primitive types), compare them directly
        // and add the key-value pair to the result if they are different
        if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          result[key] = obj1[key];
        }
      }
    }
  }

  // Return the object containing differing key-value pairs
  return result;
}
