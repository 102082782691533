// Libraries
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

// Constants
import { QUERY_KEYS } from 'constants/queries';

// Models
import { TUserInfo } from 'app/models/UserInfo';

// Services
import { getUserInfo } from 'app/services/ThirdParty/UserInfo';

type OptionHasDefault = 'queryKey' | 'queryFn';

type GetUserInfo<T> = {
  userId: string | number;
  options?: Omit<UseQueryOptions<any, any, T, any[]>, OptionHasDefault>;
};

export const useGetUserInfo = <T = TUserInfo>(props: GetUserInfo<T>) => {
  const { userId, options } = props || {};

  // Selectors
  return useQuery({
    queryKey: [QUERY_KEYS.GET_USER_INFO, userId],
    queryFn: () => getUserInfo(userId),
    placeholderData: {},
    ...options,
  });
};
