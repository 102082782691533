// Libraries
import get from 'lodash/get';

import { services } from '..';
import { APP_CONFIG } from 'constants/appConfig';

export function getList(params) {
  return services.getList({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/network/info',
  });
}

export function create(params) {
  return services.create({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/network/info',
  });
}

export function update(params) {
  return services.update({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/network/info',
  });
}

export function del(params) {
  return services.del({
    ...params,
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/network/info',
  });
}

export const getNetWorkInfo = async (appId: number | string) => {
  const response = await services.getList({
    API_HOST: APP_CONFIG.AUTH_ADX_DOMAIN + 'api/network/info',
    app_id: appId,
  });

  return get(response, 'data.data.networkInfo', {});
};
