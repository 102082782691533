// Libraries
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

interface InputNumberWrapperProps {
  isNegative?: boolean;
  sliderValue?: number;
  width?: number;
}

export const SliderWrapper = styled.div<InputNumberWrapperProps>`
  ${tw`ants-w-full`}

  .ant-slider {
    ${tw`ants-m-0`}

    .ant-slider-rail {
      ${tw`ants-bg-button-outline-border`}
    }

    .ant-slider-track {
      ${tw`ants-bg-primary`}
    }

    .ant-slider-handle {
      ${tw`ants-bg-primary ants-border-primary`}
      box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.3);
    }

    ${p => {
      const width = p.width || 0;

      return p.isNegative
        ? css`
            .ant-slider-handle-1 {
              ${tw`ants-hidden`}
            }

            .ant-slider-track {
              ${width < 0
                ? css`
                    left: auto !important;
                    right: 50% !important;
                  `
                : css`
                    left: 50% !important;
                    right: auto !important;
                  `}

              width: ${Math.abs(width)}% !important;
            }

            .ant-slider-dot {
              ${tw`ants--top-1 ants-h-3.5 ants-w-1 ants-rounded-sm ants-bg-button-outline-border ants-border-button-outline-border`}
            }
          `
        : '';
    }}

    &:hover {
      .ant-slider-handle {
        ${tw`ants-border-primary`}
      }
    }

    &.ant-slider-with-marks {
      ${tw`ants-mb-0`}
    }

    &.ant-slider-disabled {
      .ant-slider-handle {
        background-color: var(--accent-2) !important;
        border-color: var(--accent-2) !important;
      }
    }
  }
`;
