// Libraries
import styled from 'styled-components';
import AntdAlert from 'antd/lib/alert';
import tw from 'twin.macro';

// Css

export const Alert = styled(AntdAlert)`
  ${tw`ants-p-2.5 ants-text-normal`}

  .ant-alert-message {
    color: var(--text-base-color) !important;
  }

  &.ant-alert-info {
    ${tw`ants-bg-blue-3 ants-border-blue-3`}
  }
`;
