import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { layoutSaga } from './saga';
import { LayoutState } from './types';

export const initialState: LayoutState = {
  user: {
    isUserChecked: false,
    isAuthenticated: false,
    isAccessDenied: false,
    token: '',
    user_id: '',
    account_id: '',
  },
  isDebug: false,
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<any>) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    updateIsDebug(state, action: PayloadAction<any>) {
      state.isDebug = action.payload;
    },
  },
});

export const { actions: layoutActions } = slice;

export const useLayoutSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: layoutSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLayoutSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
